<template>
  <div
    :class="`notification`"
    @click="closeNotification"
    @mouseenter="pauseNotification"
    @mouseleave="resumeNotification"
    id="notification"
  >
    <h4>
      <i class="fas fa-exclamation-triangle"></i>
      {{ title }}
    </h4>
    <p><slot /></p>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: "NotificationBox",
  emits: ["destroy"],
  data() {
    return {
      intervalId: null,
    };
  },
  props: {
    title: String,
    lifetime: { type: Number, default: 5000 },
    notificationId: Number,
  },
  mounted() {
    setTimeout(() => this.$el.classList.add("resume"), 300);
    this.intervalId = setTimeout(this.closeNotification, this.lifetime);
  },
  methods: {
    pauseNotification() {
      this.$el.classList.remove("resume");
      clearInterval(this.intervalId);
    },
    resumeNotification() {
      setTimeout(() => this.$el.classList.add("resume"), 300);
      this.intervalId = setTimeout(this.closeNotification, this.lifetime);
    },
    closeNotification() {
      this.$emit("destroy", this.notificationId);
    },
  },
};
</script>

<style lang="scss">
div.notification {
  padding: 1rem 0.5rem;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 2rem;
  position: relative;
  background-color: #ffbaba;
  color: #d8000c;
  border-color: #d8000c;

  .line {
    height: 2px;
    width: 100%;
    max-width: 100%;
    transition: max-width 4900ms ease-in-out;
    background: #d8000c;
  }

  &.resume .line {
    max-width: 0;
  }
}
</style>
