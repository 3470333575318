import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const axios = require("axios").default;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? "http://localhost:5000" : "/api/";

import jwt_decode from "jwt-decode";

import WizardButton from "@/components/WizardButton";
import ProgressModal from "@/components/ProgressModal";
import FormInput from "@/components/FormInput";

const notificationsMixin = {
  data() {
    return {
      axios,
    };
  },
  methods: {
    // TODO: Needs refactoring
    notify(title, body) {
      var message;
      if (body?.response?.status == 410) {
        message =
          "Your local information token has expired and cannot be extended. " +
          "Please refresh your browser and start over";
        localStorage.removeItem("setupToken");
      } else {
        if (Array.isArray(body)) {
          message = body[0].msg;
        } else if (typeof body === "object") {
          if (
            body?.isAxiosError === true &&
            body?.response?.data?.detail === null
          ) {
            message = body.message;
          } else {
            const detail = body.response?.data?.detail;
            if (Array.isArray(detail)) {
              message = detail[0].msg;
            } else message = detail;
          }
        } else {
          message = body;
        }
      }
      this.$root.$refs.notifications.notify(
        title,
        message || "Unknown error ocurred"
      );
    },
    getLocalData() {
      const setupToken = this.getSetupToken();
      if (setupToken === null) return null;
      return jwt_decode(setupToken)?.pld;
    },
    getSetupToken() {
      return localStorage.getItem("setupToken") || null;
    },
    getRegions(callback, error) {
      let handle = axios
        .get("/service/regions")
        .then((response) => callback(response.data));
      if (error) {
        handle.catch(error);
      } else {
        handle.catch((e) => this.notify("Failed to fetch regions", e));
      }
    },
  },
};

createApp(App)
  .mixin(notificationsMixin)
  .use(router)
  .component("WizardButton", WizardButton)
  .component("ProgressModal", ProgressModal)
  .component("FormInput", FormInput)
  .mount("#app");
