<template>
  <div class="notification-area">
    <transition-group name="fade">
      <template v-for="notification in notifications" :key="notification.id">
        <notification-box
          :notification-id="notification.id"
          :title="notification.title"
          @destroy="notificationDestroyed"
          >{{ notification.body }}</notification-box
        >
      </template>
    </transition-group>
  </div>
</template>

<script>
import NotificationBox from "@/components/NotificationBox";
export default {
  data() {
    return {
      notifications: [],
      lastNotificationId: 0,
    };
  },
  name: "NotificationArea",
  components: { NotificationBox },
  methods: {
    notificationDestroyed(nid) {
      this.notifications.pop(nid);
    },
    notify(title, body) {
      this.notifications.push({ title, body, id: this.lastNotificationId++ });
    },
  },
};
</script>

<style lang="scss">
div.notification-area {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 25rem;
  max-width: 100%;
  padding: 2rem 0.5rem;
  max-height: 100vh;
}

@media only screen and (min-width: 1080px) {
  div.notification-area {
    max-width: 2rem;
  }
}
</style>
