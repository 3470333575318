<template>
  <div class="progress-modal">
    <div class="lds-dual-ring"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressModal",
};
</script>

<style lang="scss">
div.progress-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .lds-dual-ring {
    display: inline-block;
    width: 10rem;
    height: 10rem;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 8rem;
    height: 8rem;
    margin: 0.5rem;
    border-radius: 50%;
    border: 0.5rem solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
