<template>
  <div class="col-12">
    <p>
      Welcome to Bizzflow setup page. This app will allow you to select, which
      environment to setup your Bizzflow to.
    </p>
    <p>At first, please select your destination cloud provider.</p>
  </div>
  <div class="col-12">
    <h2>Select your cloud provider</h2>
    <div class="row">
      <div class="col-4">
        <image-radio
          image="gcp.png"
          id="gcp-radio"
          name="cloud-provider"
          alt="Google Cloud Platform"
          value="gcp"
          v-model="provider"
        />
      </div>
      <div class="col-4">
        <image-radio
          image="aws.png"
          id="aws-radio"
          name="cloud-provider"
          alt="Amazon Web Services"
          value="aws"
          v-model="provider"
        />
      </div>
      <div class="col-4">
        <image-radio
          image="azure.png"
          id="azure-radio"
          name="cloud-provider"
          alt="Microsoft Azure"
          value="azure"
          v-model="provider"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6" style="text-align: right">
        <wizard-button previous href="#" disabled />
      </div>
      <div class="col-6">
        <wizard-button
          next
          :disabled="provider === null"
          :href="`./credentials/${provider}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageRadio from "@/components/ImageRadio";

export default {
  name: "Home",
  components: { ImageRadio },
  data() {
    return {
      provider: null,
    };
  },
};
</script>

<style lang="scss">
@media only screen and (min-width: 1080px) {
  div.image-button img {
    max-height: 70%;
    max-width: 70%;
  }
}
</style>
