import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home";
import Credentials from "@/views/Credentials";
import Storage from "@/views/Storage";
import Git from "@/views/Git";
import EmailSettings from "@/views/EmailSettings";
import CheckDetails from "@/views/CheckDetails";
import Artifacts from "@/views/Artifacts";
import AdvancedSettingsView from "@/views/AdvancedSettingsView";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/credentials/:provider",
    name: "Connection Credentials",
    component: Credentials,
  },
  {
    path: "/storage/:provider",
    name: "Storage Credentials",
    component: Storage,
  },
  {
    path: "/git",
    name: "Git",
    component: Git,
  },
  {
    path: "/email",
    name: "Email Settings",
    component: EmailSettings,
  },
  {
    path: "/check",
    name: "Check Details",
    component: CheckDetails,
  },
  {
    path: "/artifacts",
    name: "Artifacts",
    component: Artifacts,
  },
  {
    path: "/advanced",
    name: "Advanced Settings",
    component: AdvancedSettingsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
