<template>
  <div class="col-12">
    <h2 class="pt-3">Advanced settings</h2>
    <div class="alert alert-danger row">
      <div class="col-1 d-grid" style="place-items: center">
        <i class="fas fa-exclamation-triangle fs-2"></i>
      </div>
      <div class="col-11">
        This configuration is for advanced usage and
        <strong>shouldn't be used for standard setup</strong>. If you do not
        know what you are doing, please
        <router-link to="/">go back to the standard setup</router-link>.
      </div>
    </div>
    <div class="form row">
      <div class="col-12 mt-3">
        <label for="bizzflow-image" class="form-label">Bizzflow image</label>
        <input
          type="text"
          name="bizzflow-image"
          id="bizzflow-image"
          class="form-control font-monospace"
          v-model="bizzflowImage"
        />
      </div>

      <div class="col-12 mt-3">
        <label for="backend-image" class="form-label"
          >Flow UI Backend image</label
        >
        <input
          type="text"
          name="backend-image"
          id="backend-image"
          class="form-control font-monospace"
          v-model="backendImage"
        />
      </div>

      <div class="col-12 mt-3">
        <label for="frontend-image" class="form-label"
          >Flow UI Frontend image</label
        >
        <input
          type="text"
          name="frontend-image"
          id="frontend-image"
          class="form-control font-monospace"
          v-model="frontendImage"
        />
      </div>
      <div class="col-12 form-check mt-3 mx-3">
        <label for="plan-only" class="form-check-label"
          >Only create terraform plan</label
        >
        <input
          type="checkbox"
          name="plan-only"
          id="plan-only"
          class="form-check-input"
          v-model="planOnly"
        />
      </div>
      <p class="alert alert-danger mt-3" v-if="planOnly">
        <i class="fas fa-exclamation-triangle"></i>
        This will not perform steps needed to install Bizzflow.
      </p>
    </div>
    <div class="row">
      <div class="col-6" style="text-align: right">
        <wizard-button @click="$router.push('/')" text="Back to Setup"
          >Back to Setup</wizard-button
        >
      </div>
      <div class="col-6">
        <wizard-button color-class="success" text="Save" @click="saveSettings"
          >Save</wizard-button
        >
        <wizard-button
          class="mx-3"
          color-class="danger"
          text="Delete"
          @click="deleteSettings"
          >Delete</wizard-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvancedSettingsView",
  data() {
    return {
      token: null,
      bizzflowImage: null,
      backendImage: null,
      frontendImage: null,
      planOnly: false,
    };
  },
  mounted() {
    this.applyDefaults();
  },
  methods: {
    applyDefaults() {
      const token = this.getLocalData();
      this.token = token;
      this.bizzflowImage =
        token?.advanced?.bizzflow_image ||
        "registry.gitlab.com/bizzflow-etl/toolkit:latest";
      this.backendImage =
        token?.advanced?.flow_ui_backend_image ||
        "registry.gitlab.com/bizzflow-etl/flowui/backend:latest";
      this.frontendImage =
        token?.advanced?.flow_ui_frontend_image ||
        "registry.gitlab.com/bizzflow-etl/flowui/frontend:latest";
      this.planOnly = token?.advanced?.plan_only;
    },
    async deleteSettings() {
      this.$root.displayProgressBar = true;
      try {
        const response = await this.axios.delete("/service/advanced-settings", {
          data: {
            token: this.getSetupToken(),
          },
        });
        if (response?.data?.token) {
          localStorage.setItem("setupToken", response.data.token);
        }
      } catch (error) {
        this.notify("Failed to delete advanced settings", error);
      } finally {
        this.$root.displayProgressBar = false;
        this.applyDefaults();
      }
    },
    async saveSettings() {
      this.$root.displayProgressBar = true;
      try {
        const response = await this.axios.post("/service/advanced-settings", {
          data: {
            bizzflow_image: this.bizzflowImage,
            flow_ui_backend_image: this.backendImage,
            flow_ui_frontend_image: this.frontendImage,
            plan_only: this.planOnly,
          },
          token: this.getSetupToken(),
        });
        if (response?.data?.token) {
          localStorage.setItem("setupToken", response.data.token);
        }
      } catch (error) {
        this.notify("Failed to save configuration", error);
      } finally {
        this.$root.displayProgressBar = false;
        this.applyDefaults();
      }
    },
  },
};
</script>
