<template>
  <div class="col-12">
    <p>
      This is the place to go if you want to know how your installation went.
    </p>
    <p>
      <strong
        >You may exit this page at any time. We will notify you via e-mail once
        the process is done.</strong
      >
    </p>
    <h2>Job Artifacts</h2>
  </div>
  <div class="row">
    <div class="col-12">
      <h3>
        <code>{{ job_id }}</code
        >&nbsp;<span :class="`status status--${status.toLowerCase()}`">{{
          status
        }}</span>
      </h3>
      <div class="col-12 mt-3">
        <wizard-button
          v-if="status == 'DONE' || status == 'FAILED'"
          :href="archiveLink"
          icon="fa-download"
          >Download Artifacts</wizard-button
        >
        <p v-else>
          Please wait for the process to complete to download artifacts
        </p>
        <wizard-button
          v-if="status === 'FAILED'"
          icon="fa-sync"
          @click="retryJob"
          class="mx-2"
          >Retry</wizard-button
        >
      </div>
    </div>
    <div class="col-12 mt-5">
      <h3>Log</h3>
      <pre
        class="log"
        v-if="log !== null"
      ><code v-html="toHtml(log)"></code></pre>
    </div>
  </div>
</template>

<script>
const ANSIhtml = require("ansi-to-html");
var convert = new ANSIhtml();

export default {
  name: "Artifacts",
  data() {
    const token = this.$route.query.token;
    return {
      token,
      status: "UNKNOWN",
      job_id: null,
      log: null,
      logPollError: false,
    };
  },
  mounted() {
    if (!this.token) {
      this.$router.push("/");
    }
    this.getLog();
  },
  methods: {
    toHtml(text) {
      return convert.toHtml(text);
    },
    retryJob() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(`/service/retry`, {
          token: this.token,
        })
        .then((response) =>
          this.$router.go(`/artifacts?token=${response.data.poll_token}`)
        )
        .catch((error) => this.$notify("Retry failed", error))
        .finally(() => (this.$root.displayProgressBar = false));
    },
    getStatus() {
      this.$root.displayProgressBar = true;
      this.axios
        .get(`/service/status?token=${this.token}`)
        .then((response) => {
          this.status = response.data.status;
          this.job_id = response.data.job_id;
          if (this.status === "UNKNOWN") {
            setTimeout(() => {
              this.$router.go();
            }, 7500);
          }
        })
        .catch((error) => {
          this.notify(
            "Error getting job status.",
            `Retrying in 5 seconds. ${error}`
          );
          setTimeout(this.getStatus, 5000);
        })
        .finally(() => (this.$root.displayProgressBar = false));
    },
    getLog() {
      this.axios
        .get(`/service/log?token=${this.token}`)
        .then((response) => {
          this.log = response.data.log.split("\n").reverse().join("\n");
          if (this.status === "PROCESSING" || this.status === "UNKNOWN") {
            setTimeout(this.getLog, 5000);
          }
          this.getStatus();
        })
        .catch((error) => {
          this.notify(
            "Error getting job log.",
            `Retrying in 5 seconds. ${error}`
          );
          setTimeout(this.getLog, 5000);
        })
        .finally(() => (this.$root.displayProgressBar = false));
    },
  },
  computed: {
    archiveLink: {
      get() {
        const baseUrl = this.axios.defaults.baseURL.endsWith("/")
          ? this.axios.defaults.baseURL.slice(0, -1)
          : this.axios.defaults.baseURL;
        return `${baseUrl}/service/archive?token=${this.token}`;
      },
    },
  },
};
</script>

<style lang="scss">
pre.log {
  max-height: 70vh;
  overflow: scroll;
}

span.status {
  font-weight: bold;
  &--done {
    color: #00a000;
  }

  &--unknown {
    color: #090909;
  }

  &--failed {
    color: #a00000;
  }
  &--processing {
    color: #a0a000;
  }
}
</style>
