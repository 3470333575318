<template>
  <div :class="`form-group mt-3 col-12 ${small ? 'col-md-6' : ''}`">
    <label for="aws_access_key_id">{{ title || placeholder }}</label>
    <textarea
      v-if="type == 'textarea'"
      :name="id"
      :id="id"
      :class="`form-control ${error ? 'is-invalid' : 'is-valid'}`"
      :placeholder="placeholder || title"
      v-model="value"
      @input="onInput"
      :required="required"
      :disabled="disabled"
      rows="7"
    />
    <input
      v-else
      :type="type"
      :name="id"
      :id="id"
      :class="`form-control ${error ? 'is-invalid' : 'is-valid'}`"
      :placeholder="placeholder || title"
      v-model="value"
      @input="onInput"
      :pattern="pattern"
      :required="required"
      :disabled="disabled"
    />
    <small v-if="$slots.default" class="form-text text-muted"><slot /></small>
  </div>
</template>

<script>
export default {
  name: "FormInput",
  emits: ["validationChanged", "update:modelValue"],
  data() {
    return {
      error: false,
      patt: null,
    };
  },
  props: {
    placeholder: String,
    type: String,
    id: String,
    small: Boolean,
    title: String,
    modelValue: String,
    pattern: { type: String, default: ".*" },
    required: Boolean,
    disabled: Boolean,
  },
  mounted() {
    setTimeout(this.validate, 250);
    if (this.pattern === "::email")
      this.patt = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    else this.patt = this.pattern;
  },
  methods: {
    isValidJSON(s) {
      try {
        JSON.parse(s);
      } catch (e) {
        return false;
      }
      return true;
    },
    validate() {
      var error;
      if (this.required && String(this.value).length == 0) {
        error = true;
      } else {
        if (this.pattern == "::json") {
          error = !this.isValidJSON(this.value);
        } else {
          error = !this.value?.match(this.patt);
        }
      }
      if (error != this.error) {
        this.error = error;
        this.$emit("validationChanged", !this.error);
      }
    },
    onInput() {
      this.$emit("update:modelValue", this.value);
      this.validate();
    },
  },
  computed: {
    valid: {
      get() {
        return !this.error;
      },
    },
    value: {
      set(value) {
        this.$emit("update:modelValue", value);
      },
      get() {
        return this.modelValue;
      },
    },
  },
};
</script>

<style></style>
