<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-xl-6 offset-xl-3 mt-3">
        <a href="#" @click="displayPermissions = !displayPermissions"
          ><h4>
            <i
              :class="`i-animate fas fa-chevron-right ${
                displayPermissions ? 'in' : 'out'
              }`"
            ></i
            >&nbsp;Which policies do we need?
          </h4>
        </a>
        <transition name="roll">
          <div v-if="displayPermissions">
            Following RBAC roles will be tested for presence on service
            principal you supply for the installation process.
            <strong
              >We recommend using a specialized service account for this
              purpose.</strong
            >
            <br />
            <ul class="permissions-list">
              <li>Contributor</li>
              <li>User Access Administrator</li>
            </ul>
          </div>
        </transition>
        You may find a guide to create a specialized service principal for
        Bizzflow installation
        <a
          href="https://wiki.bizzflow.net/#/install-guide?id=create-azure-service-principal-for-bizzflow-installation"
          target="_blank"
          >in the Bizzflow Wiki</a
        >
      </div>
      <div class="col-12 col-xl-6 offset-xl-3">
        <form action="/api/service/validate/google" method="post">
          <div class="row">
            <form-input
              type="text"
              id="client_id"
              ref="client_id"
              placeholder="Your client id"
              title="Client ID"
              v-model="client_id"
              @validationChanged="inputState.client_id = $event"
              :disabled="steps.service_principal"
              pattern="^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
              >Also refered to as <code>Application id</code> or
              <code>appId</code>.</form-input
            >
            <form-input
              type="text"
              id="tenant"
              ref="tenant"
              placeholder="Your tenant id"
              title="Tenant"
              v-model="tenant"
              @validationChanged="inputState.tenant = $event"
              :disabled="steps.service_principal"
              pattern="^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
              >If you didn't follow the
              <a
                href="https://wiki.bizzflow.net/#/install-guide?id=create-azure-service-principal-for-bizzflow-installation"
                target="_blank"
                >guide linked above</a
              >, you can find your Tenant id using
              <a
                href="https://docs.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant"
                target="_blank"
                >this official Azure guide</a
              >.</form-input
            >
            <form-input
              type="password"
              id="password"
              ref="password"
              placeholder="Your application's client secret"
              title="Client secret"
              v-model="password"
              @validationChanged="inputState.password = $event"
              :disabled="steps.service_principal"
              pattern="^.{8,}$"
              >Also refered to as <code>Application secret</code> or
              <code>password</code>.</form-input
            >
            <div class="form-group mt-3 col-12">
              <label for="region">Region</label>
              <select
                name="region"
                v-model="region"
                id="region"
                class="form-control is-valid"
                :disabled="resource_group !== null"
                required
              >
                <option v-for="r in regions" :key="r" :value="r">
                  {{ r }}
                </option>
              </select>
            </div>
            <div class="col-12 text-center mt-3">
              <wizard-button
                icon="fa-sync"
                :disabled="
                  !(
                    inputState.client_id &&
                    inputState.password &&
                    inputState.tenant
                  ) || steps.service_principal
                "
                @click="loadSubscriptions"
                >Load Subscriptions</wizard-button
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group mt-3 col-12">
              <label for="subscription">Subscription</label>
              <select
                name="subscription"
                v-model="subscription"
                id="subscription"
                :class="`form-control ${
                  subscription !== null ? 'is-valid' : 'is-invalid'
                }`"
                :disabled="!steps.service_principal"
                @change="subscriptionSelected"
                required
              >
                <option
                  v-for="sub in subscriptions"
                  :key="sub.id"
                  :value="sub.id"
                >
                  {{ sub.name }} ({{ sub.id }})
                </option>
              </select>
              <small class="form-text text-muted">{{ subscription }}</small>
            </div>
          </div>
          <div class="row">
            <div class="form-group mt-3 col-12">
              <label for="resource_group">Resource group</label>
              <select
                name="resource_group"
                v-model="resource_group"
                id="resource_group"
                class="form-control is-valid"
                :disabled="!steps.service_principal || !subscription"
                @change="resourceGroupSelected"
                required
              >
                <option :value="null">&lt;create new&gt;</option>
                <option
                  v-for="grp in resource_groups"
                  :key="grp.id"
                  :value="grp.id"
                  :data-region="grp.region"
                >
                  {{ grp.name }}
                </option>
              </select>
              <small class="form-text text-muted"
                >Keep empty if you want the installer to create a new one for
                you.<br />{{
                  resource_group !== null
                    ? resource_group
                    : "None selected - We will create a new one"
                }}</small
              >
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6" style="text-align: right">
        <wizard-button previous href="/" />
      </div>
      <div class="col-6">
        <wizard-button
          next
          :disabled="!Object.values(inputState).every((el) => el)"
          @click="verifyAndContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CredentialsAzure",
  data() {
    const localData = this.getLocalData();
    let client_id = localData?.azure?.client_id || "";
    let tenant = localData?.azure?.tenant || "";
    let password = localData?.azure?.password || "";
    let subscription = localData?.azure?.subscription || null;
    let region = localData?.azure?.region || "West Europe";
    let resource_group = localData?.azure?.resource_group || null;
    let initialSubscriptions =
      subscription === null
        ? []
        : [{ id: subscription, name: "<Previously selected>" }];
    let initialResourceGroups =
      resource_group === null
        ? []
        : [
            {
              id: resource_group,
              name: resource_group.split("/").reverse()[0],
            },
          ];
    return {
      displayPermissions: false,
      client_id,
      tenant,
      password,
      region,
      regions: [],
      subscription,
      subscriptions: initialSubscriptions,
      resource_group,
      resource_groups: initialResourceGroups,
      inputState: {
        client_id: false,
        tenant: false,
        password: false,
        subscription: false,
      },
      steps: {
        service_principal: false,
      },
    };
  },
  mounted() {
    this.getRegions((regions) => (this.regions = regions.azure));
    setTimeout(this.refreshValidity, 250);
  },
  methods: {
    subscriptionSelected() {
      if (this.subscriptions.map((e) => e.id).includes(this.subscription)) {
        this.inputState.subscription = true;
        this.loadResourceGroups();
      } else {
        this.inputState.subscription = false;
      }
    },
    resourceGroupSelected(el) {
      if (this.resource_group === null) return;
      var rg_region =
        el.target.options[el.target.selectedIndex]?.dataset?.region;
      if (rg_region) this.region = rg_region;
    },
    refreshValidity() {
      this.inputState = {
        client_id: this.$refs.client_id.valid,
        tenant: this.$refs.tenant.valid,
        password: this.$refs.password.valid,
        subscription: !!this.subscription,
      };
    },
    loadSubscriptions() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(`/service/azure/subscriptions`, {
          client_id: this.client_id,
          tenant: this.tenant,
          password: this.password,
          region: this.region,
        })
        .then((response) => {
          this.subscriptions = response.data;
          this.steps.service_principal = true;
          if (this.subscription) this.loadResourceGroups();
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Error loading subscriptions", error);
        })
        .finally(() => (this.$root.displayProgressBar = false));
    },
    loadResourceGroups() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(
          `/service/azure/subscriptions/${this.subscription}/resourceGroups`,
          {
            client_id: this.client_id,
            tenant: this.tenant,
            password: this.password,
            region: this.region,
          }
        )
        .then((response) => {
          this.resource_groups = response.data;
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Error loading resource groups", error);
        })
        .finally(() => (this.$root.displayProgressBar = false));
    },
    verifyAndContinue() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(`/service/validate/azure`, {
          data: {
            client_id: this.client_id,
            tenant: this.tenant,
            password: this.password,
            subscription: this.subscription,
            region: this.region,
            resource_group: this.resource_group,
          },
          token: this.getSetupToken(),
        })
        .then((response) => {
          if (response.data?.token) {
            localStorage.setItem("setupToken", response.data.token);
          }
          this.$router.push("/git");
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Credentials error", error);
        })
        .finally(() => {
          this.$root.displayProgressBar = false;
        });
    },
  },
};
</script>

<style></style>
