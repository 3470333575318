<template>
  <div class="col-12">
    <p>
      We are ready to go! Please take your time to check the information below
      before starting the installation.
    </p>
    <h2>Confirm details</h2>
    <div class="row">
      <div class="col-12 col-xl-6 offset-xl-3 mt-3">
        <table class="table details">
          <tbody>
            <tr>
              <td>Job id</td>
              <td>{{ token.job_id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="col-12 col-xl-6 offset-xl-3 mt-3"
        v-if="token.platform === 'aws'"
      >
        <h3>AWS</h3>
        <table class="table details">
          <tbody>
            <tr>
              <td>Account ID</td>
              <td>{{ token.aws.account }}</td>
            </tr>
            <tr>
              <td>User</td>
              <td>{{ token.aws.user_name }}</td>
            </tr>
            <tr>
              <td>Region</td>
              <td>{{ token.aws.region }}</td>
            </tr>
          </tbody>
        </table>
        <h3>Snowflake</h3>
        <table
          v-if="token.storage_backend === 'snowflake'"
          class="table details"
        >
          <tbody>
            <tr>
              <td>Account</td>
              <td>
                https://{{ token.snowflake.account }}.snowflakecomputing.com
              </td>
            </tr>
            <tr>
              <td>Warehouse</td>
              <td>{{ token.snowflake.warehouse }}</td>
            </tr>
            <tr>
              <td>Database</td>
              <td>{{ token.snowflake.database }}</td>
            </tr>
            <tr>
              <td>User</td>
              <td>{{ token.snowflake.user }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="col-12 col-xl-6 offset-xl-3 mt-3"
        v-else-if="token.platform === 'gcp'"
      >
        <h3>Google Cloud Platform</h3>
        <table class="table details">
          <tbody>
            <tr>
              <td>Project</td>
              <td>{{ token.gcp.project_id }}</td>
            </tr>
            <tr>
              <td>Region</td>
              <td>{{ token.gcp.region }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="col-12 col-xl-6 offset-xl-3 mt-3"
        v-else-if="token.platform === 'azure'"
      >
        <h3>Azure</h3>
        <table class="table details">
          <tbody>
            <tr>
              <td>Client ID</td>
              <td>{{ token.azure.client_id }}</td>
            </tr>
            <tr>
              <td>Tenant</td>
              <td>{{ token.azure.tenant }}</td>
            </tr>
            <tr>
              <td>Subscription</td>
              <td>{{ token.azure.subscription }}</td>
            </tr>
            <tr>
              <td>Resource group</td>
              <td>
                {{
                  token.azure.resource_group !== null
                    ? token.azure.resource_group.split("/").reverse()[0]
                    : "create new"
                }}
              </td>
            </tr>
            <tr>
              <td>Region</td>
              <td>{{ token.azure.region }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-xl-6 offset-xl-3 mt-3">
        <h3>Git Project</h3>
        <table class="table details">
          <tbody>
            <tr>
              <td>Remote</td>
              <td>{{ token.git.remote }}</td>
            </tr>
          </tbody>
        </table>
        <h3>Notifications</h3>
        <template v-if="token.notifications === 'none'">
          <strong>No notifications backend configured</strong>
        </template>
        <template v-else-if="token.notifications === 'smtp'">
          <h5>SMTP</h5>
          <table class="table details">
            <tbody>
              <tr>
                <td>Host</td>
                <td>{{ token.smtp.server }}:{{ token.smtp.port }}</td>
              </tr>
              <tr>
                <td>User</td>
                <td>{{ token.smtp.user }}</td>
              </tr>
              <tr>
                <td>Sender Address</td>
                <td>{{ token.smtp.from_address }}</td>
              </tr>
              <tr>
                <td>Security</td>
                <td>
                  {{ token.smtp.ssl ? "SSL," : "" }}
                  {{ token.smtp.starttls ? "STARTTLS" : "" }}
                </td>
              </tr>
              <tr>
                <td>Notification e-mail</td>
                <td>{{ token.notification_email }}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div v-if="token?.advanced" class="col-12 col-xl-6 offset-xl-3 mt-3">
        <h5>Advanced Configuration</h5>
        <p class="alert alert-danger">
          <i class="fas fa-exclamation-triangle"></i> I assume you know what you
          are doing.
        </p>
        <table class="table details">
          <tr class="border-bottom">
            <th>Key</th>
            <th>Value</th>
          </tr>
          <tr v-for="key in Object.keys(token.advanced)" :key="key">
            <td>
              <code>{{ key }}</code>
            </td>
            <td>{{ token.advanced[key] }}</td>
          </tr>
        </table>
      </div>
      <div class="row mt-3" style="display: none;">
        <div class="col-12 col-xl-6 offset-xl-3">
          <h3>Managed Bizzflow</h3>
          <div class="form-check">
            <input
              type="checkbox"
              name="allow_bizztreat_access"
              id="allow_bizztreat_access"
              class="form-check-input"
              v-model="allow_bizztreat_access"
            /><label class="form-check-label" for="allow_bizztreat_access"
              >(Optional) I allow Bizztreat to have access to this installation.
              This will allow ssh access by Bizztreat's deploy server
              permanently and store installation artifacts on Bizztreat's
              server.</label
            >
          </div>
          <div class="form-group">
            <label for="managed_key">Managed Bizzflow key</label>
            <input
              type="text"
              class="form-control"
              name="managed_key"
              id="managed_key"
              v-model="managed_key"
              :disabled="!allow_bizztreat_access"
            />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-xl-6 offset-xl-3">
          <h3>Agreement</h3>
          <div class="form-check">
            <input
              type="checkbox"
              name="agreed"
              id="agreed"
              class="form-check-input"
              v-model="agreed"
            /><label class="form-check-label" for="agreed"
              >I confirm that I have read, understood and I agree with
              <a href="#">terms of service</a>.</label
            >
          </div>
          <div class="form-check mb-3">
            <input
              type="checkbox"
              name="clear"
              id="clear"
              class="form-check-input"
              v-model="clear"
              disabled
            /><label class="form-check-label" for="clear"
              >Please clear all sensitive information stored in this browser
              (<strong
                >Checking this will delete data from all the forms you filled in
                previous steps. In case the installation fails, you will have to
                fill in all the forms again.</strong
              >)</label
            >
          </div>
        </div>
        <div class="col-6" style="text-align: right">
          <wizard-button previous @click="$router.go(-1)" />
        </div>
        <div class="col-6">
          <wizard-button
            text="Install"
            next
            :disabled="!agreed || (allow_bizztreat_access && !managed_key)"
            @click="verifyAndContinue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckDetails",
  data() {
    const token = this.getLocalData();
    return {
      token,
      clear: true,
      agreed: false,
      allow_bizztreat_access: false,
      managed_key: "",
    };
  },
  methods: {
    verifyAndContinue() {
      this.$root.displayProgressBar = true;
      this.axios
        .post("/service/install", {
          token: this.getSetupToken(),
          allow_bizztreat_access: this.allow_bizztreat_access,
          managed_key: this.managed_key,
        })
        .then((response) => {
          console.log({ response });
          if (this.clear) {
            localStorage.removeItem("setupToken");
          } else {
            localStorage.setItem("setupToken", response.data.token);
          }
          this.$router.push(`/artifacts?token=${response.data.poll_token}`);
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Failed to start installation", error);
        })
        .finally(() => (this.$root.displayProgressBar = false));
    },
  },
};
</script>

<style lang="scss">
table.details {
  tr {
    td:nth-child(1) {
      font-weight: bold;
    }

    td:nth-child(2) {
      font-size: 0.875em;
      color: #d63384;
      word-wrap: break-word;
      font-family: var(--bs-font-monospace);
    }
  }
}
</style>
