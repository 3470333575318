<template>
  <div class="container mt-5" id="page">
    <h1>Bizzflow Setup</h1>
    <div class="row"><router-view /></div>
    <notification-area ref="notifications" />
    <transition name="fade"
      ><progress-modal v-if="displayProgressBar"
    /></transition>
  </div>
</template>

<script>
import "@/assets/style.scss";
import NotificationArea from "@/components/NotificationArea";
export default {
  components: { NotificationArea },
  data() {
    return {
      displayProgressBar: false,
    };
  },
};
</script>

NotificationArea
<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

a {
  font-weight: bold;
}

a[target="_blank"]::after {
  display: inline;
  content: "\f35d";
  font-family: "Font Awesome 5 Free";
  font-size: 0.7rem;
  margin-left: 0.4rem;
}

ul.permissions-list {
  font-family: monospace;
  // padding: 0;
  li {
    list-style: disc;
    padding: 0.2rem 0;
    margin: 0;
  }
}
</style>
