<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3">
        <img src="@/assets/snowflake.png" alt="Snowflake" class="img-fluid" />
      </div>
      <div class="col-12 col-md-6 offset-md-3 mt-5">
        <a @click="hideBasicInfo = !hideBasicInfo"
          ><h4>
            <i
              :class="`i-animate fas fa-chevron-right ${
                hideBasicInfo ? 'out' : 'in'
              }`"
            ></i
            >&nbsp;Basic Information
          </h4></a
        >
      </div>
      <transition name="roll">
        <div v-if="!hideBasicInfo" class="col-12 col-xl-6 offset-xl-3 mb-3">
          <form action="/api/service/validate/snowflake" method="post">
            <form-input
              type="text"
              id="snowflake_account"
              ref="snowflake_account"
              placeholder="Hint: it ends with '.snowflakecomputing.com'"
              title="Account URL"
              v-model="snowflake_account"
              @validationChanged="inputState.account = $event"
              required
              pattern="^https:\/\/[a-zA-Z0-9\-_]+\.([\w\d\-]+\.)?snowflakecomputing.com$"
              >Your Snowflake account's URL will look like
              <code
                >https://&lt;account&gt;.&lt;region&gt;.snowflakecomputing.com</code
              ></form-input
            >

            <form-input
              type="text"
              id="snowflake_warehouse"
              ref="snowflake_warehouse"
              placeholder="COMPUTE_WH"
              title="Snowflake Warehouse"
              v-model="snowflake_warehouse"
              @validationChanged="inputState.warehouse = $event"
              required
              pattern="^[\w\d_]+$"
              >If you do not have one yet,
              <a
                href="https://docs.snowflake.com/en/sql-reference/sql/create-warehouse.html"
                target="_blank"
                >create a warehouse first</a
              ></form-input
            >

            <form-input
              type="text"
              id="snowflake_database"
              ref="snowflake_database"
              placeholder="BIZZFLOW"
              title="Snowflake Database"
              v-model="snowflake_database"
              @validationChanged="inputState.database = $event"
              required
              pattern="^[\w\d_]+$"
              >Please,
              <a
                href="https://docs.snowflake.com/en/sql-reference/sql/create-database.html"
                target="_blank"
                >create a database</a
              >
              that will be used only by Bizzflow.
            </form-input>

            <form-input
              type="text"
              id="snowflake_user"
              ref="snowflake_user"
              placeholder="securityadmin"
              title="Snowflake User"
              v-model="snowflake_user"
              @validationChanged="inputState.user = $event"
              required
              pattern="^[\w\d_]+$"
              >Please, make sure the user has <code>SECURITYADMIN</code> role
              granted.
            </form-input>

            <form-input
              type="password"
              id="snowflake_password"
              ref="snowflake_password"
              placeholder=""
              title="Snowflake Password"
              v-model="snowflake_password"
              @validationChanged="inputState.password = $event"
              required
              pattern="^.+$"
            />
          </form>
        </div>
      </transition>
    </div>
    <div class="row">
      <div class="col-6" style="text-align: right">
        <wizard-button previous href="/credentials/aws" />
      </div>
      <div class="col-6">
        <wizard-button
          next
          :disabled="!Object.values(inputState).every((el) => el)"
          @click="verifyAndContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StorageSnowflake",
  data() {
    const localData = this.getLocalData();
    let snowflake_account = "";
    if (localData?.snowflake?.account)
      snowflake_account = `https://${localData.snowflake.account}.snowflakecomputing.com`;
    let snowflake_warehouse = localData?.snowflake?.warehouse || "";
    let snowflake_database = localData?.snowflake?.database || "";
    let snowflake_password = localData?.snowflake?.password || "";
    let snowflake_user = localData?.snowflake?.user || "";
    return {
      snowflake_account,
      snowflake_warehouse,
      snowflake_database,
      snowflake_password,
      snowflake_user,
      hideBasicInfo: false,
      inputState: {
        account: false,
        database: false,
        warehouse: false,
        user: false,
        password: false,
      },
    };
  },
  mounted() {
    setTimeout(this.refreshValidity, 250);
  },
  methods: {
    refreshValidity() {
      this.inputState = {
        account: this.$refs.snowflake_account.valid,
        database: this.$refs.snowflake_database.valid,
        warehouse: this.$refs.snowflake_warehouse.valid,
        user: this.$refs.snowflake_user.valid,
        password: this.$refs.snowflake_password.valid,
      };
    },
    verifyAndContinue() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(`/service/validate/snowflake`, {
          data: {
            account: this.snowflake_account,
            database: this.snowflake_database,
            warehouse: this.snowflake_warehouse,
            user: this.snowflake_user,
            password: this.snowflake_password,
          },
          token: this.getSetupToken(),
        })
        .then((response) => {
          if (response.data?.token) {
            localStorage.setItem("setupToken", response.data.token);
          }
          this.$router.push("/git");
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Snowflake Credentials Error", error);
        })
        .finally(() => {
          this.$root.displayProgressBar = false;
        });
    },
  },
};
</script>

<style></style>
