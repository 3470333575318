<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-xl-6 offset-xl-3 mt-3">
        <a href="#" @click="displayPermissions = !displayPermissions"
          ><h4>
            <i
              :class="`i-animate fas fa-chevron-right ${
                displayPermissions ? 'in' : 'out'
              }`"
            ></i
            >&nbsp;Which roles do we need?
          </h4>
        </a>
        <transition name="roll">
          <div v-if="displayPermissions">
            Following roles will be tested for presence on service account you
            supply for the installation process.
            <strong
              >We recommend using a specialized service account for this
              purpose.</strong
            ><br />
            <ul class="permissions-list">
              <li>Editor</li>
              <li>Security Admin</li>
              <li>Service Networking Admin</li>
            </ul>
          </div>
        </transition>
      </div>
      <div class="col-12 col-xl-6 offset-xl-3">
        <form action="/api/service/validate/google" method="post">
          <div class="row">
            <form-input
              type="textarea"
              id="service_account"
              ref="service_account"
              placeholder="Your Google Service Account key"
              title="Service Account Key"
              v-model="service_account"
              @validationChanged="inputState.service_account = $event"
              pattern="::json"
              :disabled="steps.service_account"
            />
            <div class="form-group mt-3 col-12">
              <label for="region">Region</label>
              <select
                name="region"
                v-model="region"
                id="region"
                class="form-control is-valid"
                required
              >
                <option v-for="r in regions" :key="r" :value="r">
                  {{ r }}
                </option>
              </select>
            </div>
            <div class="col-12 text-center mt-3">
              <wizard-button
                icon="fa-sync"
                :disabled="!inputState.service_account || steps.service_account"
                @click="loadProjects"
                >Load Projects</wizard-button
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group mt-3 col-12">
              <label for="project_id">Project</label>
              <select
                name="project_id"
                v-model="selected_project"
                id="project_id"
                :class="`form-control ${
                  selected_project !== null ? 'is-valid' : 'is-invalid'
                }`"
                :disabled="!steps.service_account"
                @change="projectSelected"
                required
              >
                <option
                  v-for="project in projects"
                  :key="project.id"
                  :value="project.id"
                >
                  {{ project.name }} ({{ project.id }})
                </option>
              </select>
              <small class="form-text text-muted">{{ selected_project }}</small>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6" style="text-align: right">
        <wizard-button previous href="/" />
      </div>
      <div class="col-6">
        <wizard-button
          next
          :disabled="!Object.values(inputState).every((el) => el)"
          @click="verifyAndContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CredentialsGoogle",
  data() {
    const localData = this.getLocalData();
    let service_account = localData?.gcp?.service_account || "";
    let selected_project = localData?.gcp?.project_id || null;
    let region = localData?.gcp?.region || "europe-west4";
    let initialProjects = [];
    if (selected_project !== null)
      initialProjects = [
        { id: selected_project, name: "<Previously selected>" },
      ];
    return {
      displayPermissions: false,
      service_account,
      selected_project,
      region,
      regions: [],
      projects: initialProjects,
      steps: {
        service_account: false,
        project: false,
      },
      inputState: {
        service_account: false,
        project: false,
      },
    };
  },
  mounted() {
    this.getRegions((regions) => (this.regions = regions.gcp));
    setTimeout(this.refreshValidity, 250);
  },
  methods: {
    refreshValidity() {
      this.inputState = {
        service_account: this.$refs.service_account.valid,
        project: !!this.selected_project,
      };
    },
    projectSelected() {
      if (this.selected_project) {
        this.inputState.project = true;
        this.steps.project = true;
      } else {
        this.inputState.project = false;
        this.steps.project = false;
      }
    },
    loadProjects() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(`/service/gcp/projects`, {
          service_account: this.service_account,
          region: this.region,
        })
        .then((response) => {
          this.projects = response.data;
          this.steps.service_account = true;
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Error loading projects", error);
        })
        .finally(() => (this.$root.displayProgressBar = false));
    },
    verifyAndContinue() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(`/service/validate/gcp`, {
          data: {
            service_account: this.service_account,
            project_id: this.selected_project,
            region: this.region,
          },
          token: this.getSetupToken(),
        })
        .then((response) => {
          if (response.data?.token) {
            localStorage.setItem("setupToken", response.data.token);
          }
          this.$router.push("/git");
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Credentials error", error);
        })
        .finally(() => {
          this.$root.displayProgressBar = false;
        });
    },
  },
};
</script>

<style></style>
