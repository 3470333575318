<template>
  <div class="col-12">
    <p>
      Every Bizzflow installation needs a storage to operator on. In this part
      of the guide, please specify your storage settings. Again, make sure you
      have read our <a href="#">privacy policy</a> and
      <a href="#">license information</a>
      first.
    </p>
    <div class="row">
      <component :is="providers[$route.params.provider]?.view" />
    </div>
  </div>
</template>

<script>
import StorageSnowflake from "@/views/StorageSnowflake";
import { shallowRef } from "vue";

const providers = {
  snowflake: { title: "Snowflake", view: shallowRef(StorageSnowflake) },
  gcp: { title: "BigQuery" },
  azure: { title: "Azure SQL Server" },
};

export default {
  name: "Storage",
  data() {
    return {
      providers,
    };
  },
  mounted() {
    if (
      !Object.prototype.hasOwnProperty.call(
        this.providers,
        this.$route.params.provider
      )
    ) {
      this.$router.replace("/");
    }
  },
};
</script>

<style></style>
