<template>
  <div class="col-12">
    <p>
      Every Bizzflow project needs to reside in a git repository.
      Please use the form below to specify, where your project should be.
    </p>
    <p>
      At this time, this installer only supports
      <code>SSH key</code> authentication.
    </p>
    <h2>Repository information</h2>
    <div class="row">
      <div class="col-12 col-xl-6 offset-xl-3">
        <form method="post" action="/api/service/validate/git">
          <form-input
            name="remote"
            id="remote"
            v-model="remote"
            type="text"
            ref="remote"
            placeholder="git@github.com:path/to/repo.git"
            title="Repository remote"
            pattern="^.*$"
            @validationChanged="inputState.remote = $event"
            >A remote should look like
            <code>git@domain.tld:path/to/repository.git</code></form-input
          >

          <form-input
            type="textarea"
            name="ssh_key"
            id="ssh_key"
            v-model="ssh_key"
            ref="ssh_key"
            :placeholder="'-----BEGIN RSA PRIVATE KEY-----\n...'"
            pattern=".+"
            title="Private SSH key"
            @validationChanged="inputState.ssh_key = $event"
          >
            SSH private key with read/write permissions to your repository.<br />
            <code>ssh-keygen -t rsa -m PEM -b 4096</code> should give you a
            strong enough key.<br />
            <strong
              >Please note that password-protected keys are not
              supported</strong
            >
          </form-input>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-6 offset-xl-3 mt-5">
        <p>
          Please note, that after clicking <code>Next</code> we will test write
          permissions to the repository by creating a file in its root and
          commiting it to the origin.
          <strong>Bizzflow works best with empty repositories</strong>.
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6" style="text-align: right">
        <wizard-button previous @click="$router.go(-1)" />
      </div>
      <div class="col-6">
        <wizard-button
          next
          :disabled="!Object.values(inputState).every((el) => el)"
          @click="verifyAndContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Git",
  mounted() {
    setTimeout(this.refreshValidity, 250);
  },
  data() {
    const localData = this.getLocalData();
    let remote = localData?.git?.remote || null;
    let ssh_key = localData?.git?.ssh_key || null;
    return {
      remote,
      ssh_key,
      inputState: {
        remote: false,
        ssh_key: false,
      },
    };
  },
  methods: {
    refreshValidity() {
      this.inputState = {
        remote: this.$refs.remote.valid,
        ssh_key: this.$refs.ssh_key.valid,
      };
    },
    verifyAndContinue() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(`/service/validate/git`, {
          data: {
            ssh_key: this.ssh_key,
            remote: this.remote,
          },
          token: this.getSetupToken(),
        })
        .then((response) => {
          if (response.data?.token) {
            localStorage.setItem("setupToken", response.data.token);
          }
          this.$router.push("/email");
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Git error", error);
        })
        .finally(() => (this.$root.displayProgressBar = false));
    },
  },
};
</script>

<style></style>
