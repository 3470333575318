<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-xl-6 offset-xl-3">
        <form action="/api/service/validate/aws" method="post">
          <div class="row">
            <form-input
              type="text"
              id="aws_access_key_id"
              ref="aws_access_key_id"
              @validationChanged="inputState.access_key = $event"
              placeholder="Your AWS access key id"
              title="AWS Access Key ID"
              v-model="aws_access_key_id"
              pattern="^A(K|S)IA[A-Z0-9]{16}$"
            />
            <form-input
              type="password"
              id="aws_secret_access_key"
              ref="aws_secret_access_key"
              @validationChanged="inputState.secret_key = $event"
              placeholder="Your AWS secret access key"
              title="AWS Secret Access Key"
              v-model="aws_secret_access_key"
              pattern="^[A-Za-z0-9\+=\/]{40}$"
              >Please review our privacy policy before you leave us with your
              credentials</form-input
            >
            <div class="form-group mt-3 col-12">
              <label for="region">Region</label>
              <select
                name="region"
                v-model="region"
                id="region"
                class="form-control is-valid"
                required
              >
                <option v-for="r in regions" :key="r" :value="r">
                  {{ r }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 col-xl-6 offset-xl-3 mt-3">
        <a href="#" @click="displayPermissions = !displayPermissions"
          ><h4>
            <i
              :class="`i-animate fas fa-chevron-right ${
                displayPermissions ? 'in' : 'out'
              }`"
            ></i
            >&nbsp;Which policies do we need?
          </h4>
        </a>
        <transition name="roll">
          <div v-if="displayPermissions">
            Following IAM actions are required to be allowed for the IAM user
            you supply for the installation process.
            <strong
              >If the user used for the setup process doesn't have policies that
              allow them to perform actions listed below, the installation will
              fail.</strong
            >
            <br />
            We recommend using a specialized user with programatic access and
            strongly discourage you from using a personal, let alone an
            administrator account.<br />
            <ul
              class="permissions-list mt-3"
              style="max-height: 200px; overflow-y: scroll"
            >
              <li v-for="policy in awsPolicies" :key="policy">{{ policy }}</li>
            </ul>
            <p class="mt-3">
              You can also use this AWS policy document to create a policy
              containing all of the required policies to deploy Bizzflow into
              your AWS account.
              <strong
                >Please note that the policies have no
                <code>Resource</code> conditions by default.</strong
              >
            </p>
            <pre
              style="max-height: 200px; overflow-y: scroll"
            ><code>{{ JSON.stringify(awsPolicyDocument, null, 2) }}</code></pre>
          </div>
        </transition>
      </div>
    </div>
    <div class="row">
      <div class="col-6" style="text-align: right">
        <wizard-button previous href="/" />
      </div>
      <div class="col-6">
        <wizard-button
          next
          :disabled="!(aws_access_key_id && aws_secret_access_key)"
          @click="verifyAndContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
const awsPolicies = [
  "ec2:AllocateAddress",
  "ec2:AssociateAddress",
  "ec2:AssociateRouteTable",
  "ec2:AttachInternetGateway",
  "ec2:AuthorizeSecurityGroupEgress",
  "ec2:AuthorizeSecurityGroupIngress",
  "ec2:CreateInternetGateway",
  "ec2:CreateRoute",
  "ec2:CreateRouteTable",
  "ec2:CreateSecurityGroup",
  "ec2:CreateSubnet",
  "ec2:CreateTags",
  "ec2:CreateVpc",
  "ec2:DeleteKeyPair",
  "ec2:DeleteSecurityGroup",
  "ec2:DeleteSubnet",
  "ec2:DescribeAccountAttributes",
  "ec2:DescribeAddresses",
  "ec2:DescribeImages",
  "ec2:DescribeInstanceAttribute",
  "ec2:DescribeInstanceCreditSpecifications",
  "ec2:DescribeInstances",
  "ec2:DescribeInternetGateways",
  "ec2:DescribeKeyPairs",
  "ec2:DescribeNetworkAcls",
  "ec2:DescribeNetworkInterfaces",
  "ec2:DescribeRouteTables",
  "ec2:DescribeSecurityGroups",
  "ec2:DescribeSubnets",
  "ec2:DescribeTags",
  "ec2:DescribeVolumes",
  "ec2:DescribeVpcAttribute",
  "ec2:DescribeVpcClassicLink",
  "ec2:DescribeVpcClassicLinkDnsSupport",
  "ec2:DescribeVpcs",
  "ec2:ImportKeyPair",
  "ec2:ModifySubnetAttribute",
  "ec2:ModifyVpcAttribute",
  "ec2:ReleaseAddress",
  "ec2:RevokeSecurityGroupEgress",
  "ec2:RevokeSecurityGroupIngress",
  "ec2:RunInstances",
  "iam:AddRoleToInstanceProfile",
  "iam:AttachRolePolicy",
  "iam:AttachUserPolicy",
  "iam:CreateAccessKey",
  "iam:CreateInstanceProfile",
  "iam:CreatePolicy",
  "iam:CreateRole",
  "iam:CreateUser",
  "iam:GetInstanceProfile",
  "iam:GetPolicy",
  "iam:GetPolicyVersion",
  "iam:GetRole",
  "iam:GetUser",
  "iam:ListAccessKeys",
  "iam:ListAttachedRolePolicies",
  "iam:ListAttachedUserPolicies",
  "iam:ListGroupsForUser",
  "iam:ListRolePolicies",
  "iam:ListUserPolicies",
  "iam:PassRole",
  "iam:TagRole",
  "iam:TagUser",
  "kms:CreateGrant",
  "kms:Decrypt",
  "kms:DescribeKey",
  "kms:GenerateDataKey",
  "rds:AddTagsToResource",
  "rds:CreateDBInstance",
  "rds:CreateDBParameterGroup",
  "rds:CreateDBSubnetGroup",
  "rds:DeleteDBSubnetGroup",
  "rds:DescribeDBInstances",
  "rds:DescribeDBParameterGroups",
  "rds:DescribeDBParameters",
  "rds:DescribeDBSubnetGroups",
  "rds:ListTagsForResource",
  "rds:ModifyDBParameterGroup",
  "s3:CreateBucket",
  "s3:DeleteBucket",
  "s3:GetAccelerateConfiguration",
  "s3:GetBucketAcl",
  "s3:GetBucketCORS",
  "s3:GetBucketLogging",
  "s3:GetBucketObjectLockConfiguration",
  "s3:GetBucketRequestPayment",
  "s3:GetBucketTagging",
  "s3:GetBucketVersioning",
  "s3:GetBucketWebsite",
  "s3:GetEncryptionConfiguration",
  "s3:GetLifecycleConfiguration",
  "s3:GetReplicationConfiguration",
  "s3:ListAllMyBuckets",
  "s3:ListBucket",
  "s3:PutBucketTagging",
  "secretsmanager:CreateSecret",
  "secretsmanager:TagResource",
];

const awsPolicyDocument = {
  Version: "2012-10-17",
  Statement: [
    {
      Effect: "Allow",
      Action: [
        "ec2:AllocateAddress",
        "ec2:AssociateAddress",
        "ec2:AssociateRouteTable",
        "ec2:AttachInternetGateway",
        "ec2:AuthorizeSecurityGroupEgress",
        "ec2:AuthorizeSecurityGroupIngress",
        "ec2:CreateInternetGateway",
        "ec2:CreateRoute",
        "ec2:CreateRouteTable",
        "ec2:CreateSecurityGroup",
        "ec2:CreateSubnet",
        "ec2:CreateTags",
        "ec2:CreateVpc",
        "ec2:DeleteKeyPair",
        "ec2:DeleteSecurityGroup",
        "ec2:DeleteSubnet",
        "ec2:DescribeAccountAttributes",
        "ec2:DescribeAddresses",
        "ec2:DescribeImages",
        "ec2:DescribeInstanceAttribute",
        "ec2:DescribeInstanceCreditSpecifications",
        "ec2:DescribeInstances",
        "ec2:DescribeInternetGateways",
        "ec2:DescribeKeyPairs",
        "ec2:DescribeNetworkAcls",
        "ec2:DescribeNetworkInterfaces",
        "ec2:DescribeRouteTables",
        "ec2:DescribeSecurityGroups",
        "ec2:DescribeSubnets",
        "ec2:DescribeTags",
        "ec2:DescribeVolumes",
        "ec2:DescribeVpcAttribute",
        "ec2:DescribeVpcClassicLink",
        "ec2:DescribeVpcClassicLinkDnsSupport",
        "ec2:DescribeVpcs",
        "ec2:ImportKeyPair",
        "ec2:ModifySubnetAttribute",
        "ec2:ModifyVpcAttribute",
        "ec2:ReleaseAddress",
        "ec2:RevokeSecurityGroupEgress",
        "ec2:RevokeSecurityGroupIngress",
        "ec2:RunInstances",
      ],
      Resource: "*",
    },
    {
      Effect: "Allow",
      Action: [
        "iam:AddRoleToInstanceProfile",
        "iam:AttachRolePolicy",
        "iam:AttachUserPolicy",
        "iam:CreateAccessKey",
        "iam:CreateInstanceProfile",
        "iam:CreatePolicy",
        "iam:CreateRole",
        "iam:CreateUser",
        "iam:GetInstanceProfile",
        "iam:GetPolicy",
        "iam:GetPolicyVersion",
        "iam:GetRole",
        "iam:GetUser",
        "iam:ListAccessKeys",
        "iam:ListAttachedRolePolicies",
        "iam:ListAttachedUserPolicies",
        "iam:ListGroupsForUser",
        "iam:ListRolePolicies",
        "iam:ListUserPolicies",
        "iam:PassRole",
        "iam:TagRole",
        "iam:TagUser",
      ],
      Resource: "*",
    },
    {
      Effect: "Allow",
      Action: [
        "kms:CreateGrant",
        "kms:Decrypt",
        "kms:DescribeKey",
        "kms:GenerateDataKey",
      ],
      Resource: "*",
    },
    {
      Effect: "Allow",
      Action: [
        "rds:AddTagsToResource",
        "rds:CreateDBInstance",
        "rds:CreateDBParameterGroup",
        "rds:CreateDBSubnetGroup",
        "rds:DeleteDBSubnetGroup",
        "rds:DescribeDBInstances",
        "rds:DescribeDBParameterGroups",
        "rds:DescribeDBParameters",
        "rds:DescribeDBSubnetGroups",
        "rds:ListTagsForResource",
        "rds:ModifyDBParameterGroup",
      ],
      Resource: "*",
    },
    {
      Effect: "Allow",
      Action: [
        "s3:CreateBucket",
        "s3:DeleteBucket",
        "s3:GetAccelerateConfiguration",
        "s3:GetBucketAcl",
        "s3:GetBucketCORS",
        "s3:GetBucketLogging",
        "s3:GetBucketObjectLockConfiguration",
        "s3:GetBucketRequestPayment",
        "s3:GetBucketTagging",
        "s3:GetBucketVersioning",
        "s3:GetBucketWebsite",
        "s3:GetEncryptionConfiguration",
        "s3:GetLifecycleConfiguration",
        "s3:GetReplicationConfiguration",
        "s3:ListAllMyBuckets",
        "s3:ListBucket",
        "s3:PutBucketTagging",
      ],
      Resource: "*",
    },
    {
      Effect: "Allow",
      Action: ["secretsmanager:CreateSecret", "secretsmanager:TagResource"],
      Resource: "*",
    },
  ],
};

export default {
  name: "CredentialsAWS",
  data() {
    const localData = this.getLocalData();
    let aws_access_key_id = localData?.aws?.access_key_id;
    let aws_secret_access_key = localData?.aws?.secret_access_key;
    let region = localData?.aws?.region || "eu-west-1";
    return {
      displayPermissions: false,
      aws_access_key_id,
      aws_secret_access_key,
      region,
      regions: [],
      inputState: {
        access_key: false,
        secret_key: false,
      },
      awsPolicies,
      awsPolicyDocument,
    };
  },
  mounted() {
    this.getRegions((regions) => (this.regions = regions.aws));
    setTimeout(this.refreshValidity, 250);
  },
  methods: {
    refreshValidity() {
      this.inputState = {
        access_key: this.$refs.aws_access_key_id.valid,
        secret_key: this.$refs.aws_secret_access_key.valid,
      };
    },
    verifyAndContinue() {
      this.$root.displayProgressBar = true;
      this.axios
        .post(`/service/validate/aws`, {
          data: {
            access_key_id: this.aws_access_key_id,
            secret_access_key: this.aws_secret_access_key,
            region: this.region,
          },
          token: this.getSetupToken(),
        })
        .then((response) => {
          if (response.data?.token) {
            localStorage.setItem("setupToken", response.data.token);
          }
          this.$router.push("/storage/snowflake");
        })
        .catch((error) => {
          console.log({ error });
          this.notify("Credentials error", error);
        })
        .finally(() => {
          this.$root.displayProgressBar = false;
        });
    },
  },
};
</script>

<style></style>
