<template>
  <div class="col-12">
    <p>
      Now it's time to provide us with the credentials to the target cloud
      environment. Please, read our <a href="#">privacy policy</a> and
      <a href="#">license information</a>
      first.
    </p>
    <h2>
      Please supply your credentials for
      {{ providers[$route.params.provider]?.title }}
    </h2>
    <div class="row">
      <component markRaw :is="providers[$route.params.provider]?.view" />
    </div>
  </div>
</template>

<script>
import CredentialsAWS from "@/views/CredentialsAWS";
import CredentialsAzure from "@/views/CredentialsAzure";
import CredentialsGoogle from "@/views/CredentialsGoogle";
import { shallowRef } from "vue";
export default {
  name: "Credentials",
  data() {
    return {
      providers: {
        gcp: {
          title: "Google Cloud Platform",
          view: shallowRef(CredentialsGoogle),
        },
        aws: { title: "Amazon Web Services", view: shallowRef(CredentialsAWS) },
        azure: { title: "Microsoft Azure", view: shallowRef(CredentialsAzure) },
      },
    };
  },
  mounted() {
    if (
      !Object.prototype.hasOwnProperty.call(
        this.providers,
        this.$route.params.provider
      )
    ) {
      this.$router.replace("/");
    }
  },
};
</script>

<style></style>
