<template>
  <div class="col-12">
    <p>
      In order to receive notifications from Bizzflow, you need to setup your
      notifications environment. Currently, only <code>SMTP</code> (E-mail) is
      supported.
    </p>
    <h2>Notifications Settings</h2>
    <div class="row">
      <div class="col-12 col-xl-6 offset-xl-3">
        <form action="/api/service/validate/smtp" method="post">
          <div class="row">
            <div class="form-check" style="display: none;">
              <input
                type="radio"
                name="type"
                id="type-none"
                class="form-check-input"
                value="none"
                v-model="type"
              /><label class="form-check-label" for="type-none"
                >No notifications</label
              >
            </div>
            <div class="form-check">
              <input
                type="radio"
                name="type"
                id="type-smtp"
                class="form-check-input"
                value="smtp"
                v-model="type"
                checked
              /><label class="form-check-label" for="type-smtp"
                >SMTP (E-mail)</label
              >
            </div>
          </div>
          <div class="row border p-2">
            <fieldset :disabled="type != 'smtp'">
              <form-input
                type="text"
                title="SMTP Server"
                id="smtp_server"
                ref="smtp_server"
                @validationChanged="inputState.smtp_server = $event"
                v-model="smtp_server"
                pattern="^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$"
              />
              <form-input
                type="text"
                title="SMTP Port"
                id="smtp_port"
                ref="smtp_port"
                @validationChanged="inputState.smtp_port = $event"
                v-model="smtp_port"
                pattern="^\d+$"
                >Standard ports are: <code>587</code>, <code>465</code> and
                <code>25</code></form-input
              >
              <form-input
                type="text"
                title="Username"
                id="smtp_user"
                ref="smtp_user"
                @validationChanged="inputState.smtp_user = $event"
                v-model="smtp_user"
                required
              />
              <form-input
                type="password"
                title="Password"
                id="smtp_pass"
                ref="smtp_pass"
                @validationChanged="inputState.smtp_pass = $event"
                v-model="smtp_pass"
                required
              />
              <form-input
                type="email"
                title="E-mail From"
                id="smtp_from"
                ref="smtp_from"
                @validationChanged="inputState.smtp_from = $event"
                v-model="smtp_from"
                pattern="::email"
                required
                >The address that the notifications will appear to come from.
                This address must be allowed to send e-mails using the specified
                SMTP server / user combination.</form-input
              >
              <form-input
                type="email"
                title="Notification e-mail"
                id="notification_email"
                ref="notification_email"
                @validationChanged="inputState.notification_email = $event"
                v-model="notification_email"
                pattern="::email"
                required
                >The address that will receive all notifications.
                <strong
                  >This address will also receive an e-mail once the
                  installation is complete.</strong
                ></form-input
              >
              <div class="form-check mt-3">
                <input
                  type="checkbox"
                  name="smtp_ssl"
                  id="smtp_ssl"
                  class="form-check-input"
                  v-model="smtp_ssl"
                /><label class="form-check-label" for="smtp_ssl">Use SSL</label>
              </div>
              <div class="form-check mt-3">
                <input
                  type="checkbox"
                  name="smtp_starttls"
                  id="smtp_starttls"
                  class="form-check-input"
                  v-model="smtp_starttls"
                /><label class="form-check-label" for="smtp_starttls"
                  >Use STARTTLS</label
                >
              </div>
            </fieldset>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6" style="text-align: right">
        <wizard-button previous @click="$router.go(-1)" />
      </div>
      <div class="col-6">
        <wizard-button
          next
          :disabled="
            !Object.values(inputState).every((el) => el) && type === 'smtp'
          "
          @click="verifyAndContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailSettings",
  data() {
    const localData = this.getLocalData();
    let type = localData?.notifications || "smtp";
    let smtp_server = localData?.smtp?.server;
    let smtp_port = String(localData?.smtp?.port || 587);
    let smtp_user = localData?.smtp?.user;
    let smtp_pass = localData?.smtp?.password;
    let smtp_from = localData?.smtp?.from_address;
    let smtp_ssl = localData?.smtp?.ssl;
    let smtp_starttls = localData?.smtp?.starttls;
    let notification_email = localData?.notification_email;
    return {
      type,
      smtp_server,
      smtp_port,
      smtp_user,
      smtp_pass,
      smtp_from,
      smtp_ssl,
      notification_email,
      smtp_starttls,
      inputState: {
        smtp_server: false,
        smtp_port: false,
        smtp_user: false,
        smtp_pass: false,
        smtp_from: false,
        notification_email: false,
      },
    };
  },
  mounted() {
    setTimeout(this.refreshValidity, 250);
  },
  methods: {
    refreshValidity() {
      this.inputState = {
        smtp_server: this.$refs.smtp_server.valid,
        smtp_port: this.$refs.smtp_port.valid,
        smtp_user: this.$refs.smtp_user.valid,
        smtp_pass: this.$refs.smtp_pass.valid,
        smtp_from: this.$refs.smtp_from.valid,
        notification_email: this.$refs.notification_email.valid,
      };
    },
    verifyAndContinue() {
      this.$root.displayProgressBar = true;
      if (this.type === "smtp") {
        this.axios
          .post(`/service/validate/smtp`, {
            data: {
              server: this.smtp_server,
              port: this.smtp_port,
              user: this.smtp_user,
              password: this.smtp_pass,
              starttls: this.smtp_starttls,
              ssl: this.smtp_ssl,
              from_address: this.smtp_from,
            },
            token: this.getSetupToken(),
            notification_email: this.notification_email,
          })
          .then((response) => {
            if (response.data?.token) {
              localStorage.setItem("setupToken", response.data.token);
            }
            this.$router.push("/check");
          })
          .catch((error) => {
            console.log({ error });
            this.notify("Error validating SMTP credentials", error);
          })
          .finally(() => (this.$root.displayProgressBar = false));
      } else if (this.type === "none") {
        this.axios
          .post(`/service/validate/no-notifications`, {
            token: this.getSetupToken(),
          })
          .then((response) => {
            if (response.data?.token) {
              localStorage.setItem("setupToken", response.data.token);
            }
            this.$router.push("/check");
          })
          .catch((error) => {
            console.log({ error });
            this.notify("Unexpected error ocurred", error);
          })
          .finally(() => (this.$root.displayProgressBar = false));
      }
    },
  },
};
</script>

<style></style>
