<template>
  <a
    @click="$emit('click')"
    :href="href || '#'"
    :class="`btn btn-${colorClass} btn-wizard ${disabled ? 'disabled' : ''}`"
    ><template v-if="invert"><i :class="`fa ${iconName}`"></i>&nbsp;</template
    >{{ text
    }}<template v-if="!invert">&nbsp;<i :class="`fa ${iconName}`"></i></template
  ></a>
</template>

<script>
export default {
  name: "WizardButton",
  emits: ["click"],
  data() {
    return {
      text: null,
      iconName: null,
      invert: false,
    };
  },
  props: {
    next: Boolean,
    previous: Boolean,
    icon: String,
    disabled: Boolean,
    href: String,
    colorClass: {
      type: String,
      default: "primary",
    },
  },
  mounted() {
    if (this.next) {
      this.text = this.text || "Next";
      this.iconName = "fa-chevron-right";
    } else if (this.previous) {
      this.text = "Previous";
      this.iconName = "fa-chevron-left";
      this.invert = true;
    } else {
      this.text = this.$slots.default()[0].children;
      this.iconName = this.icon;
    }
  },
};
</script>

<style lang="scss">
a.btn-wizard {
  min-width: 7rem;
  line-height: 2rem;
}
</style>
