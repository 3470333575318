<template>
  <div class="radio">
    <input
      type="radio"
      :id="id"
      :name="name"
      :value="value"
      @input="$emit('update:modelValue', value)"
    />
    <label :for="id"
      ><slot></slot
      ><img :src="require(`@/assets/${image}`)" class="img-fluid" :alt="alt"
    /></label>
  </div>
</template>

<script>
export default {
  name: "ImageRadio",
  data() {
    return {
      selected: null,
    };
  },
  props: {
    id: String,
    name: String,
    value: String,
    image: String,
    alt: String,
  },
};
</script>

<style lang="scss">
div.radio {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  * {
    cursor: pointer;
  }

  label {
    width: 100%;
    height: 100%;
  }

  input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }

  input[type="radio"] ~ label {
    border: 2px solid transparent;
    padding: 0.5rem;
    transition: border-color 250ms ease-in-out;
  }

  input[type="radio"]:checked ~ label {
    border-color: #650b61;
    border-radius: 1rem;
    box-shadow: 0 0 0.4rem #650b61aa;
  }
}

@media only screen and (min-width: 1080px) {
  div.radio {
    label {
      max-height: 70%;
      max-width: 70%;
    }
  }
}
</style>
